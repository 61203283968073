import styled from "styled-components"
import { BREAKPOINT, LG } from "../../../utils/constants"

export const PlayIconWrap = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 47%;
  top: 40%;

  display: flex;
  align-items: center;
  transition: ${props => props.theme.defaultTransition()};
  transform: translate(-50%, -50%);

  @media (max-width: ${LG}) {
    top: ${props => (props.bigCard === true ? "80px" : "45px")};
  }
  @media (max-width: ${BREAKPOINT}) {
    top: ${props => (props.bigCard === true ? "80px" : "45px")};
  }

  img {
    width: 30% !important;
    margin: 0 auto;
  }

  z-index: 10;
`

export const MaskHover = styled.div`
  background: linear-gradient(
    180deg,
    rgba(243, 139, 151, 0.8) 48.81%,
    rgba(244, 144, 77, 0.8) 100%
  );
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 8px;
  opacity: 0;
  visibility: hidden;

  transition: ${props => props.theme.defaultTransition()};
`

export const Card = styled.div`
  border-radius: 8px;
  box-shadow: 2px 6px 48px rgba(243, 139, 151, 0.27);
  background-color: white;
  background: url(${props => props.bg}) no-repeat center center / cover;
  cursor: pointer;
 

  > div {
    width: 100%;
    height: 200px;
  }

  svg {
    position: absolute;
    left: 25%;
    width: 40px;
    top: 4px;
    transform: translate(4px, 0px);
  }

  &:hover {
   
    ${MaskHover} {
      opacity: 0.8;
      visibility: visible;
    }

    > div {
      ${PlayIconWrap} {
        width: 96px;
        height: 96px;
        background-color: white;
      }
      svg {
        left: 34.5%;
        top: 4px;
        transform: translate(4px, 0px);
        path {
          fill: #f4904d;
        }
      }
    }
  }

  h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${props => props.theme.pink};
  }

  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.01em;
    color: ${props => props.theme.neutralDark};
    margin-top: 5px;
  }

  > div {
    width: 100%;
    height: ${props => (props.bigCard ? "390px" : "230px")};
    @media (max-width: ${LG}) {
      height: ${props => (props.bigCard ? "240px" : "230px")};
    }
    @media (max-width: ${BREAKPOINT}) {
      height: ${props => (props.bigCard ? "240px" : "230px")};
    }
  }
`

export const TextContainer = styled.div`
  padding: 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  z-index: 9;
`
