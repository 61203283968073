import React, { Fragment, useState, useEffect } from "react"
import { useStaticQuery } from "gatsby"
import {
  Wrapper,
  Container,
  Filter,
  ContentButtons,
  Grid,
  FilterLabel,
  FilterBtnAll,
  FilterBtn,
} from "./style"
import VideoCard from "../../common/VideoCard"
import Vimeo from "@u-wave/react-vimeo"
import Rodal from "rodal"
import "rodal/lib/rodal.css"

const StoriesVideos = () => {
  const {
    allWordpressWpStories: { edges },
  } = useStaticQuery(graphql`
    query StoriesPage {
      allWordpressWpStories(limit: 100) {
        edges {
          node {
            acf {
              name
              video_link
              video_thumbnail {
                source_url
              }
            }
            id
            categories {
              id
              name
              slug
            }
          }
        }
      }
    }
  `)
  const [results, setResults] = useState([])
  const [categories, setCategories] = useState([])
  const [modalShow, setModalShow] = useState({
    visible: false,
    video: null,
  })
  const [active, setActive] = useState(null)

  const handleChangeModal = link => {
    setModalShow({
      visible: true,
      video: link,
    })
  }
  useEffect(() => {
    setResults(edges)
    edges.forEach(({ node }) => categories.push(node.categories[0].slug))
    const distinct = (value, index, self) => {
      return self.indexOf(value) === index
    }
    const categoriesFilter = categories.filter(distinct)
    setCategories(categoriesFilter)

    if (modalShow.visible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterBy = (value, index) => {
    setActive(index, active)

    setResults(edges)
    if (value !== "all") {
      setResults(
        edges.filter(r => {
          return r.node.categories[0].slug === value
        })
      )
    }
  }

  return (
    <Wrapper>
      <Rodal
        animation={"fade"}
        visible={modalShow.visible}
        onClose={() =>
          setModalShow({
            visible: false,
            video: null,
          })
        }
      >
        {modalShow.video !== null && (
          <div>
            <Vimeo video={modalShow.video} width={"100%"} frameBorder="0" />
          </div>
        )}
      </Rodal>
      <Filter>
        <Container>
          <FilterLabel
            data-aos="fade"
            data-aos-duration="1000"
            data-aos-delay={"1200"}
          >
            Filter By:
          </FilterLabel>
          <ContentButtons>
            <FilterBtnAll
              as="button"
              primary={"A"}
              onClick={() => filterBy("all")}
              active={active}
              data-aos="fade"
              data-aos-duration="1000"
              data-aos-delay={"600"}
            >
              <span>All</span>
            </FilterBtnAll>
            {categories.map((category, index) => (
              <FilterBtn
                as="button"
                key={index}
                index={index}
                primary={"A"}
                active={active}
                onClick={() => filterBy(category, index)}
                data-aos="fade"
                data-aos-duration="1000"
                data-aos-delay={
                  index === 0 ? "800" : index === 1 ? "1200" : "1600"
                }
              >
                {category}
              </FilterBtn>
            ))}
          </ContentButtons>
        </Container>
      </Filter>
      <Container>
        <Grid>
          {results.map(({ node }, i) => (
            <Fragment key={node.id}>
              <VideoCard
                id={i}
                node={node}
                changeModal={handleChangeModal}
                bigCard
              />
            </Fragment>
          ))}
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default StoriesVideos
