import React, { useState } from "react"
import { Card, TextContainer, PlayIconWrap, MaskHover } from "./style"
import SVGIcon from "../SVGIcon/index"

const VideoCard = ({ node, changeModal, bigCard, id }) => {
  const changeModalFx = e => {
    changeModal(e)
  }

  const [clientX, setClientX] = useState(0)

  const handleMouseDown = e => {
    setClientX(e.clientX)
  }

  return (
    <Card
      bg={node.acf.video_thumbnail && node.acf.video_thumbnail.source_url}
      bigCard={bigCard ? true : false}
      data-aos="fade-up"
      data-aos-duration="1000"
      data-aos-delay={id % 2 === 0 ? 200 : 400}
    >
      {/* eslint-disable-next-line */}
      <div
        onMouseDown={handleMouseDown}
        onClick={e => {
          const variation = e.clientX - clientX
          if (variation > -10 && variation < 10) {
            changeModalFx(node.acf.video_link)
          }
        }}
      >
        <PlayIconWrap bigCard={bigCard ? true : false}>
          <SVGIcon name="play" />
        </PlayIconWrap>

        <TextContainer>
          {node.categories.map((item, i) => (
            <h5 key={i}>{item.name}</h5>
          ))}
          <h6>{node.acf.name}</h6>
        </TextContainer>
        <MaskHover />
      </div>
    </Card>
  )
}

export default VideoCard
