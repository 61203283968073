import styled from "styled-components"
import { MainH2, MainH3 } from "../../../utils/GlobalComponents"
import { XL, LG, BREAKPOINT } from "../../../utils/constants"

export const Wrapper = styled.div`
  padding: 100px 0;
  margin: 0 auto;
  max-width: 1000px;


  @media (max-width: ${XL}) {
    max-width: 800px;
  }
  @media (max-width: ${LG}) {
    max-width: 650px;
  }
  @media (max-width: ${BREAKPOINT}) {
    max-width: 100%;
    padding: 120px 40px 0;
  }
`

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;

  @media (max-width: ${LG}) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${BREAKPOINT}) {
    padding-bottom: 55px;
  }
`

export const Title = styled(MainH2)`
  max-width: none;
  margin-bottom: 30px;

  @media (max-width: ${BREAKPOINT}) {
    line-height: 35px;
    margin-bottom: 24px;
  }
`
export const Subtitle = styled(MainH3)`
  font-size: 24px;
  line-height: 31px;
  @media (max-width: ${BREAKPOINT}) {
    margin-bottom: 24px;
  }
`

export const BlobWrap = styled.div`
  overflow: hidden;
  
`
