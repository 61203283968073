import styled from "styled-components"
import {
  MainH3,
  PrimaryBtn,
  SecondaryBtn,
} from "../../../utils/GlobalComponents"
import {
  XL,
  LG,
  BREAKPOINT_CEL_ONLY,
  BREAKPOINT,
} from "../../../utils/constants"

import CloseX from "../../../images/close-video-modal.svg"

export const Wrapper = styled.div`
  background-color: ${props => props.theme.neutralSilver};

  .rodal-mask {
    position: absolute;
    background: #faf7f5;
    opacity: 0.8;
  }

  .rodal-dialog {
    width: 80% !important;
  }

  .rodal-close {
    background-color: white;
    box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.12);
    width: 26px;
    height: 21px;
    padding: 36px;
    opacity: 1;
    border-radius: 50%;
    background: url(${CloseX}) no-repeat center center;
    background-color: white;
    top: -50px;
    right: -70px;
    transition: ${props => props.theme.defaultTransition()};
    z-index: 999;

    &:hover {
      box-shadow: 0px 4px 9px rgba(0, 43, 52, 0.3);
    }

    &:after,
    &:before {
      display: none;
    }
    @media (max-width: ${LG}) {
      top: 10%;
      right: -5%;
      padding: 30px;
    }

    @media (max-width: ${BREAKPOINT_CEL_ONLY}) {
      top: 30%;
      right: -7vw;
    }
  }
`

export const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  @media (max-width: ${XL}) {
    max-width: 800px;
  }
  @media (max-width: ${LG}) {
    max-width: 650px;
  }
  @media (max-width: ${BREAKPOINT}) {
    max-width: 100%;
    padding: 0 40px;
  }
`

export const Filter = styled.div`
  background-color: ${props => props.theme.white};
  padding-bottom: 45px;
  @media (max-width: ${BREAKPOINT}) {
    padding: 25px 0;
  }
`

export const ContentButtons = styled.div`
  display: flex;
  button {
    padding: 0 32px;
    border: 0;
    height: 40px;
    margin-right: 18px;
  }
  @media (max-width: ${BREAKPOINT}) {
    display: block;
    button {
      display: inline-block;
      width: auto;
      margin: 0 16px 16px 0;
    }
  }
`

export const Grid = styled.div`
  padding: 150px 0;
  margin: 0 -12px;
  @media (max-width: ${LG}) {
    padding: 80px 0;
  }
  @media (max-width: ${BREAKPOINT}) {
    padding: 54px 0 44px;
  }
  & > div {
    position: relative;
    display: inline-block;
    width: calc(50% - 24px);
    margin: 12px;

    @media (max-width: ${BREAKPOINT}) {
      display: block;
      width: 100%;
      margin: 0 0 24px 0;
    }
  }
  iframe {
    height: 100%;
  }
`

export const FilterLabel = styled(MainH3)`
  text-transform: uppercase;
  color: ${props => props.theme.neutralGreen};
  margin-bottom: 26px;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 500;
`

export const FilterBtnAll = styled(PrimaryBtn)`
  box-shadow: none;
  border: 0 !important;
  background: ${props =>
    props.active === props.index || props.active === null
      ? props.theme.greenGradient
      : "rgba(176, 201, 203, 0.22)"};

  color: ${props =>
    props.active === props.index || props.active === null
      ? "white"
      : props.theme.navy};
  transition: ${props => props.theme.defaultTransition()};

  &:before {
    border: 0;
  }

  span {
    opacity: 1;
  }

  &:hover {
    box-shadow: none;
    background-color: ${props => props.theme.neutralGreen};
    &:after,
    &:before {
      display: none;
    }
  }
`

export const FilterBtn = styled(SecondaryBtn)`
  background: ${props =>
    props.active === props.index
      ? props.theme.greenGradient
      : "rgba(176, 201, 203, 0.22)"};
  color: ${props =>
    props.active === props.index ? "white" : props.theme.navy};
  text-transform: capitalize;

  span {
    opacity: 1;
  }
  &:hover {
    background-color: ${props => props.theme.neutralGreen};
  }
`
