import React, { Fragment } from "react"
import { DefaultWrapper } from "../../../utils/GlobalComponents"
import { useMediaQuery } from "react-responsive"
import { TitleContainer, Paragraph } from "../../Home/AboutUs/style"
import Blob from "../../common/Blob/Blob"

import { Wrapper, Grid, Title, Subtitle, BlobWrap } from "./style"

const StoriesHero = () => {
  const isTabletOrMobileDevice = useMediaQuery({
    query: "(max-device-width: 991px)",
  })

  const isNotebookOrIpad = useMediaQuery({
    query: "(max-device-width: 1199px)",
  })

  return (
    <DefaultWrapper>
      <BlobWrap>
        <Blob
          toTop={
            isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "20%" : "30%"
          }
          toRight={
            isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "15%" : "10%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "10vw" : "20vw"
          }
          customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
          delay={"400"}
        />
        <Blob
          toTop={
            isTabletOrMobileDevice ? "17%" : isNotebookOrIpad ? "20%" : "28%"
          }
          toRight={
            isTabletOrMobileDevice ? "13%" : isNotebookOrIpad ? "25%" : "20%"
          }
          customWidth={
            isTabletOrMobileDevice ? "0" : isNotebookOrIpad ? "5vw" : "7vw"
          }
          customHeight={isNotebookOrIpad ? "50vw" : "40.1vw"}
          delay={"400"}
        />
        <Wrapper data-aos="fade-up" data-aos-duration="600">
          <Fragment>
            <Title>LymeMIND Stories</Title>
            <Grid>
              <TitleContainer>
                <Subtitle
                  data-aos="fade-up"
                  data-aos-duration="600"
                  data-aos-delay="300"
                >
                  The LymeMIND consortium is one of the main pillars of
                  LymeMIND’s integrated solution.
                </Subtitle>
              </TitleContainer>
            </Grid>
          </Fragment>
        </Wrapper>
      </BlobWrap>
    </DefaultWrapper>
  )
}

export default StoriesHero
