import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from "../components/Stories/Hero"
import Videos from "../components/Stories/Videos"

const StoriesPage = () => {
  return (
    <Layout>
      <SEO title="Stories" />
      <Hero />
      <Videos />
    </Layout>
  )
}

export default StoriesPage
